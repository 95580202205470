import {AbstractSharedFolderDialogsManagerService, SharedFolderCreateUpdateDialogData} from '|shared';
import {inject, Injectable} from '@angular/core';
import {
  CreateUpdateSharedFolderDialogComponent
} from '../modules/create-update-shared-folder-dialog/create-update-shared-folder-dialog.component';
import {SharedFolderDto} from '|api/document';
import {IczModalService, IczModalSizeClass} from '@icz/angular-modal';

@Injectable()
export class SharedFolderDialogsManagerService implements AbstractSharedFolderDialogsManagerService {

  private modalService = inject(IczModalService);

  openSharedFolderCreateUpdateDialog(dialogData: SharedFolderCreateUpdateDialogData) {
    return this.modalService.openComponentInModal<Nullable<SharedFolderDto>, SharedFolderCreateUpdateDialogData>({
      component: CreateUpdateSharedFolderDialogComponent,
      modalOptions: {
        sizeClass: IczModalSizeClass.WH_RESPONSIVE,
        titleTemplate: dialogData.isCreateMode ? 'Založení sdílené složky' : 'Úprava sdílené složky {{name}}',
        titleTemplateContext: {
          name: dialogData.sharedFolder?.name ?? '',
        },
        disableAutoMargin: true,
      },
      data: dialogData,
    });
  }

}
