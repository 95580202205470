/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ESSL14319CirculationTaskState } from '../../models/essl-14319-circulation-task-state';
import { ESSL14319EntityType } from '../../models/essl-14319-entity-type';

export interface CirculationActivityReturnEntityFromIssdInternal$Params {
  taskState: ESSL14319CirculationTaskState;
  identifier: string;
  idSource: string;
  entityType: ESSL14319EntityType;
}

export function circulationActivityReturnEntityFromIssdInternal(http: HttpClient, rootUrl: string, params: CirculationActivityReturnEntityFromIssdInternal$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, circulationActivityReturnEntityFromIssdInternal.PATH, 'patch');
  if (params) {
    rb.path('taskState', params.taskState, {});
    rb.path('identifier', params.identifier, {});
    rb.path('idSource', params.idSource, {});
    rb.path('entityType', params.entityType, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

circulationActivityReturnEntityFromIssdInternal.PATH = '/flow/activity/return-entity-from-issd-internal/{taskState}/{identifier}/{idSource}/{entityType}';